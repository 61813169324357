/**
 * @description 공통 설정 정보
 * @interface IConfig
 * @property {string} apiBaseUrl api prefix
 * @property {string} googleClientId 구글 클라이언트 아이디
 * @property {number} apiTimeOut axios API timeout 딜레이
 * @property {number} toastTimeOut 토스트 팝업 유지 시간
 */
const Config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string,
  googleClientId:
    '118215903538-8jv4vkia5ds77r0ku14n2b1tncjtr6qq.apps.googleusercontent.com',
  apiTimeOut: 15000,
  toastTimeOut: 1500
};

export default Config;
