import React from 'react';
import { IconDetail } from '@src/resources/icon';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/component/ColumnHead.Comp';
import { useAlert as alert } from '@hooks/modal/useAlert';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { TPTooltipBasic } from '@units/tooltip/TPTooltip.Basic';
import { usePutStatus as putStatus } from '@hooks/users/putStatus';
import { usePutDelete as putDelete } from '@hooks/users/putDelete';
import { useNavigate as Navigate } from 'react-router-dom';
import { usePutUsers as putUsers } from '@hooks/users/putUsers';

export const accountColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'userId',
      value: '순번',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'email',
      value: '이메일',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'name',
      value: '사용자명',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'useStatus',
      value: '사용여부',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: 'boolean'
    },
    {
      code: 'projectManagerLeader',
      value: 'PM리더',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: 'boolean'
    },
    {
      code: 'projectManager',
      value: 'PM',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: 'boolean'
    },
    {
      code: 'languageLeader',
      value: '언어리더',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: 'boolean'
    },
    {
      code: 'worker',
      value: '작업자',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: 'boolean'
    },
    {
      code: 'avatarImgUrl',
      value: '프로필 사진',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <TPTooltipBasic title={rowData.name}>
            <TPAvatarBasic src={rowData.avatarImgUrl} />
          </TPTooltipBasic>
        );
      }
    },
    {
      code: 'slackId',
      value: '슬랙 로우 ID',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'updatedDateTime',
      value: '마지막 업데이트',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        let lastUpdate = `${format(
          new Date(rowData.updatedDateTime),
          'yyyy-MM-dd'
        )}`;
        if (rowData.lastUpdateUserName) {
          lastUpdate += `<br/><strong>${rowData.lastUpdateUserName}</strong>`;
        }
        return <p dangerouslySetInnerHTML={{ __html: lastUpdate }}></p>;
      }
    },
    {
      code: 'option',
      value: '',
      width: 24,
      renderRowData: (data: any, rowData: any) => {
        const navigate = Navigate();
        const { resetPassword } = putUsers();
        const { changeStatus } = putStatus();
        const { deleteUser } = putDelete();
        const { openHandle: openAlert } = alert();
        const { name, email, useStatus, userId } = rowData;
        let menusArr: {
          clickMenu: () => void;
          label: string;
          to?: string;
          state?: {};
        }[] = [
          {
            clickMenu: () => {
              navigate(`/account/modify/${userId}`);
            },
            label: '수정하기'
          },

          {
            clickMenu: () => {
              openAlert({
                title: useStatus ? '계정 비활성화' : '계정 활성화',
                text: `${email}(${name})<br />
                    계정을 ${useStatus ? '비활성화 ' : '활성화 '}하겠습니까?`,
                cancelLabel: '취소',
                ok: () => {
                  changeStatus({
                    userId,
                    userStatus: useStatus ? 'INACTIVE' : 'ACTIVE'
                  });
                }
              });
            },
            label: useStatus ? '비활성화' : '활성화'
          },
          {
            clickMenu: () => {
              openAlert({
                title: '계정 삭제',
                text: `${email}(${name})<br />
                    계정을 삭제하겠습니까?<br/>
                    삭제 시 복구가 불가능합니다.`,
                cancelLabel: '취소',
                ok: () => {
                  deleteUser({
                    userId
                  });
                }
              });
            },
            label: '삭제하기'
          }
          // {
          //   clickMenu: () => {
          //     openAlert({
          //       title: '비밀번호 초기화',
          //       text: `${email}(${name})<br />
          //           계정의 비밀번호를 초기화 하시겠습니까?<br/>
          //           초기화 시 복구가 불가능합니다.`,
          //       cancelLabel: '취소',
          //       ok: () => {
          //         resetPassword({ userId });
          //       }
          //     });
          //   },
          //   label: '비밀번호<br/>초기화'
          // }
        ];

        return menusArr.length > 0 ? (
          <TPPopoverMenu
            popoverMenus={menusArr}
            clickPopoverButton={(e: any) => {
              e.stopPropagation();
            }}
          >
            <TPButtonAction icon={<IconDetail />} size="small" />
          </TPPopoverMenu>
        ) : null;
      }
    }
  ] as const;
